// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-read-tsx": () => import("./../../../src/pages/blog/read.tsx" /* webpackChunkName: "component---src-pages-blog-read-tsx" */),
  "component---src-pages-home-home-tsx": () => import("./../../../src/pages/home/Home.tsx" /* webpackChunkName: "component---src-pages-home-home-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-gettuned-index-tsx": () => import("./../../../src/pages/projects/gettuned/index.tsx" /* webpackChunkName: "component---src-pages-projects-gettuned-index-tsx" */),
  "component---src-pages-projects-gettuned-privacy-tsx": () => import("./../../../src/pages/projects/gettuned/privacy.tsx" /* webpackChunkName: "component---src-pages-projects-gettuned-privacy-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-tempoist-index-tsx": () => import("./../../../src/pages/projects/tempoist/index.tsx" /* webpackChunkName: "component---src-pages-projects-tempoist-index-tsx" */),
  "component---src-pages-resume-awards-tsx": () => import("./../../../src/pages/resume/awards.tsx" /* webpackChunkName: "component---src-pages-resume-awards-tsx" */),
  "component---src-pages-resume-education-tsx": () => import("./../../../src/pages/resume/education.tsx" /* webpackChunkName: "component---src-pages-resume-education-tsx" */),
  "component---src-pages-resume-index-tsx": () => import("./../../../src/pages/resume/index.tsx" /* webpackChunkName: "component---src-pages-resume-index-tsx" */),
  "component---src-pages-resume-interests-tsx": () => import("./../../../src/pages/resume/interests.tsx" /* webpackChunkName: "component---src-pages-resume-interests-tsx" */),
  "component---src-pages-resume-skills-tsx": () => import("./../../../src/pages/resume/skills.tsx" /* webpackChunkName: "component---src-pages-resume-skills-tsx" */),
  "component---src-pages-resume-work-experience-tsx": () => import("./../../../src/pages/resume/work_experience.tsx" /* webpackChunkName: "component---src-pages-resume-work-experience-tsx" */),
  "component---src-pages-tunelyzer-tsx": () => import("./../../../src/pages/tunelyzer.tsx" /* webpackChunkName: "component---src-pages-tunelyzer-tsx" */)
}

